<template lang="">
  <v-select
    v-model="isComplete"
    :items="itemsCompleted"
    label="Wszyscy"
    solo
    hide-details
  ></v-select>
</template>
<script>
export default {
  props: {
    isCompleted: {
      type: Boolean,
    },
  },
  data() {
    return {
      isComplete: '',
      itemsCompleted: [
        {
          text: 'Zrealizowane',
          value: true,
        },
        {
          text: 'W trakcie',
          value: false,
        },
        {
          text: 'Wszyscy',
          value: '',
        },
      ],
    };
  },
  watch: {
    isComplete(val) {
      this.$emit('update:isCompleted', val);
    },
    isCompleted(val) {
      this.isCompleted = val;
    },
  },
  created() {
    this.isComplete = '';
  },
};
</script>
<style lang=""></style>
