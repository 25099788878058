export const officeHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullname',
    sortable: false,
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Instalator', value: 'fitter.fullname' },
  { text: 'Handlowiec', value: 'trader.fullname' },
  { text: 'Status', value: 'status.nameForUser' },
  { text: 'Data utworzenia', value: 'createdAt' },
  { text: '', value: 'actions', sortable: false },
];

export const fitterHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullname',
    sortable: false,
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Status', value: 'status.nameForUser' },
  { text: 'Miejscowość', value: 'city' },
  { text: 'Montaż', value: 'installationDate' },
  { text: 'Data utworzenia', value: 'createdAt' },
  { text: '', value: 'actions', sortable: false },
];

export const traderHeaders = [
  {
    text: 'Imię i nazwisko',
    align: 'start',
    value: 'fullname',
    sortable: false,
  },
  { text: 'Numer telefonu', value: 'phone' },
  { text: 'Oferta', value: 'offerDate' },
  { text: 'Status', value: 'status.nameForUser' },
  { text: 'Faktura prow.', value: 'fvDate' },
  { text: 'Data utworzenia', value: 'createdAt' },
  { text: '', value: 'actions', sortable: false },
];
