<template lang="">
  <v-row class="align-center">
    <v-col cols="12" sm="2">
      <v-text-field v-model="filters.search" hide-details placeholder="Szukaj" solo></v-text-field
    ></v-col>
    <v-col cols="12" sm="2">
      <status-filter v-bind:status.sync="filters.status"></status-filter
    ></v-col>

    <v-col cols="12" sm="2">
      <v-select
        v-model="filters.isOfferIssued"
        :items="isOfferIssuedOptions"
        label="Oferta"
        solo
        hide-details
      ></v-select
    ></v-col>
    <v-col cols="12" sm="2">
      <v-select
        v-model="filters.isTraderFVIssued"
        :items="isTraderFVIssuedOptions"
        label="Faktura"
        solo
        hide-details
      ></v-select
    ></v-col>
    <v-col cols="12" sm="2">
      <complete-filter v-bind:isCompleted.sync="filters.isCompleted"></complete-filter
    ></v-col>

    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn></v-col
    >
    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
        <v-icon>mdi-cancel</v-icon>
      </v-btn></v-col
    >
  </v-row>
</template>

<script>
import StatusFilter from './filters/StatusFilter.vue';
import CompleteFilter from './filters/CompleteFilter.vue';

export default {
  name: 'FitterFilters',
  components: {
    CompleteFilter,
    StatusFilter,
  },
  data() {
    return {
      fitters: [],
      traders: [],
      statuses: [],
      isOfferIssuedOptions: [
        {
          text: 'Wygenerowana oferta',
          value: true,
        },
        {
          text: 'Nie wygenerowana oferta',
          value: false,
        },
      ],
      isTraderFVIssuedOptions: [
        {
          text: 'Wystawiona faktura',
          value: true,
        },
        {
          text: 'Nie wystawiona faktura',
          value: false,
        },
      ],
      fitter: '',
      trader: '',
      filters: {
        search: '',
        status: '',
        isCompleted: '',
        isOfferIssued: '',
        isTraderFVIssued: '',
      },
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.filters);
    },
    clearParams() {
      this.filters = {
        search: '',
        trader: '',
        fitter: '',
        status: '',
        isCompleted: '',
      };
      this.trader = '';
      this.fitter = '';

      this.$emit('clearParams');
    },
  },
};
</script>
<style lang=""></style>
