<template>
  <title-wrapper title="Zarządzaj klientami">
    <client-data-table
      :data="clients"
      :headers="headers"
      :loading="loading"
      :length="length"
      :page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <office-filters
          v-if="isOffice"
          @search="search"
          @clearParams="clearParams"
        ></office-filters>
        <trader-filters
          v-if="isTrader"
          @search="search"
          @clearParams="clearParams"
        ></trader-filters>
        <fitter-filters
          v-if="isFitter"
          @search="search"
          @clearParams="clearParams"
        ></fitter-filters>
      </template>
      <template v-slot:actions="slotProps">
        <table-popup-router
          :to="{
            name: 'ClientDetails',
            params: { clientId: slotProps.item.id },
          }"
          content="Wyświetl szczegóły"
        />
      </template>
    </client-data-table>
    <primary-button v-if="isOffice" :to="{ name: 'AddClient' }"
      ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon>Dodaj klienta</primary-button
    >
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import ClientDataTable from '../../components/client/ClientDataTable.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import OfficeFilters from '../../components/client/OfficeFilters.vue';
import FitterFilters from '../../components/client/FitterFilters.vue';
import TraderFilters from '../../components/client/TraderFilters.vue';
import TablePopupRouter from '../../components/TablePopupRouter.vue';

import { sortHandler } from '../../mixins/sortHandler';
import { mapGetters } from 'vuex';

import { fitterHeaders, traderHeaders, officeHeaders } from '../../headers/clientsListHeaders';

export default {
  name: 'ClientsList',
  components: {
    TitleWrapper,
    ClientDataTable,
    PrimaryButton,
    OfficeFilters,
    FitterFilters,
    TraderFilters,
    TablePopupRouter,
  },
  mixins: [sortHandler],
  data() {
    return {
      loading: true,
      count: 0,
      length: 0,
      clients: [],
      filters: {},
      params: {
        page: 1,
        limit: 10,
        sort: '',
      },
      headers: [],
      detailsContent: ['Wyświetl szczegóły', 'test'],
    };
  },

  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  computed: {
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
  },
  methods: {
    clearParams() {
      this.filters = {};
      this.params = {
        page: 1,
        limit: 10,
        sort: '',
      };
      this.getFiltered();
    },
    search(filters) {
      this.filters = filters;
      this.getFiltered();
    },
    getParams() {
      return {
        ...this.params,
        ...this.filters,
      };
    },
    async getFiltered() {
      try {
        const response = await this.$store.dispatch('client/getAll', this.getParams());
        this.clients = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.params.limit);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    chooseHeaders() {
      if (this.isOffice) {
        this.headers = officeHeaders;
      } else if (this.isTrader) {
        this.headers = traderHeaders;
      } else if (this.isFitter) {
        this.headers = fitterHeaders;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  activated() {
    this.chooseHeaders();
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped></style>
