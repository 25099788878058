<template>
  <v-row class="align-center" v-if="!loading">
    <v-col cols="12" sm="2">
      <v-text-field v-model="filters.search" hide-details placeholder="Szukaj" solo></v-text-field
    ></v-col>
    <v-col cols="12" sm="2"
      ><status-filter v-bind:status.sync="filters.status"></status-filter>
    </v-col>
    <v-col cols="12" sm="2">
      <v-select
        v-model="filters.fitterId"
        :items="fitters"
        item-text="fullName"
        item-value="_id"
        label="Instalator"
        solo
        hide-details
      ></v-select
    ></v-col>
    <v-col cols="12" sm="2">
      <v-select
        v-model="filters.traderId"
        :items="traders"
        item-text="fullName"
        item-value="_id"
        label="Handlowiec"
        solo
        hide-details
      ></v-select
    ></v-col>
    <v-col cols="12" sm="2">
      <complete-filter v-bind:isCompleted.sync="filters.isCompleted"></complete-filter
    ></v-col>

    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn></v-col
    >
    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
        <v-icon>mdi-cancel</v-icon>
      </v-btn></v-col
    >
  </v-row>
</template>

<script>
import CompleteFilter from './filters/CompleteFilter.vue';
import StatusFilter from './filters/StatusFilter.vue';
export default {
  name: 'OfficeFilters',
  components: {
    CompleteFilter,
    StatusFilter,
  },
  data() {
    return {
      loading: true,
      fitters: [],
      traders: [],
      statuses: [],
      filters: {
        search: '',
        traderId: '',
        fitterId: '',
        status: '',
        isCompleted: '',
      },
    };
  },
  methods: {
    onSearch() {
      if (this.trader) {
        this.filters.traderId = this.findByName(this.trader, this.traders);
      }

      if (this.fitter) {
        this.filters.fitterId = this.findByName(this.fitter, this.fitters);
      }

      this.$emit('search', this.filters);
    },
    findByName(fullname, list) {
      return list.find((item) => item.fullname === fullname).id;
    },
    clearParams() {
      this.filters = {
        search: '',
        trader: '',
        fitter: '',
        status: '',
        isCompleted: '',
      };
      this.trader = '';
      this.fitter = '';

      this.$emit('clearParams');
    },
    async fetchData() {
      try {
        this.fitters = await this.$store.dispatch('user/getFitters');
        this.traders = await this.$store.dispatch('user/getTraders');
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
<style lang=""></style>
