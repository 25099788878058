var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-container',{staticClass:"px-0"},[_vm._t("filters"),_c('v-container',{staticClass:"white my-4"},[_c('v-row',[_c('v-col',[_c('v-data-table',_vm._g({staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"hide-default-footer":"","sortBy":_vm.sortBy,"sortDesc":_vm.sortDesc},scopedSlots:_vm._u([{key:"item.installationDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.installationDate))+" ")])]}},{key:"item.offerDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.offerDate))+" ")])]}},{key:"item.fvDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.fvDate))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.status.nameForUser",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status.color || 'primary'}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.status.nameForUser ? item.status.nameForUser : 'Błąd'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status.textForUser || 'Informacja nie została udzielona dla aktualnego statusu. Proszę skonsultować się z pomocą'))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-card',[_vm._t("actions",null,{"item":item})],2)],1)]}}],null,true)},_vm.$listeners))],1)],1),_vm._t("below")],2),(_vm.isPaginationVisible)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.length,"total-visible":"5"},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }