<template lang="">
  <v-select
    v-model="filterStatus"
    :items="mapStatuses"
    label="Status"
    solo
    hide-details
    :loading="loading"
  ></v-select>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      statuses: [],
      filterStatus: '',
      itemsCompleted: [
        {
          text: 'Zrealizowane',
          value: true,
        },
        {
          text: 'W trakcie',
          value: false,
        },
        {
          text: 'Wszyscy',
          value: '',
        },
      ],
    };
  },
  watch: {
    filterStatus(val) {
      this.$emit('update:status', val);
    },
    status(val) {
      this.filterStatus = val;
    },
  },
  computed: {
    mapStatuses() {
      return Object.values(this.statuses).map((status) => status.nameForUser);
    },
  },
  methods: {
    onSearch() {
      if (this.trader) {
        this.filters.traderId = this.findByName(this.trader, this.traders);
      }

      if (this.fitter) {
        this.filters.fitterId = this.findByName(this.fitter, this.fitters);
      }

      this.$emit('search', this.filters);
    },
    findByName(fullname, list) {
      return list.find((item) => item.fullname === fullname).id;
    },
    clearParams() {
      this.filters = {
        search: '',
        trader: '',
        fitter: '',
        status: '',
        isCompleted: '',
      };
      this.trader = '';
      this.fitter = '';

      this.$emit('clearParams');
    },
    async fetchData() {
      this.loading = true;
      try {
        this.statuses = await this.$store.dispatch('static/getClientStatuses');
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
  created() {
    this.filterStatus = '';
  },
};
</script>
<style lang=""></style>
