<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"> </v-progress-linear>
    <v-container v-else class="px-0">
      <slot name="filters"></slot>
      <v-container class="white my-4">
        <v-row
          ><v-col
            ><v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="10"
              hide-default-footer
              class="elevation-0"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              v-on="$listeners"
            >
              <template v-slot:[`item.installationDate`]="{ item }">
                <span>
                  {{ formatDate(item.installationDate) }}
                </span>
              </template>
              <template v-slot:[`item.offerDate`]="{ item }">
                <span>
                  {{ formatDate(item.offerDate) }}
                </span>
              </template>
              <template v-slot:[`item.fvDate`]="{ item }">
                <span>
                  {{ formatDate(item.fvDate) }}
                </span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span> {{ formatDate(item.createdAt) }} </span>
              </template>
              <template v-slot:[`item.status.nameForUser`]="{ item }">
                <v-chip :color="item.status.color || 'primary'"
                  ><span class="white--text">{{
                    item.status.nameForUser ? item.status.nameForUser : 'Błąd'
                  }}</span></v-chip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="info" class="ml-1" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{
                    item.status.textForUser ||
                    'Informacja nie została udzielona dla aktualnego statusu. Proszę skonsultować się z pomocą'
                  }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
                  <template v-slot:activator="{ on }">
                    <v-icon small color="black" class="mr-2" v-on="on"> mdi-dots-vertical </v-icon>
                  </template>
                  <v-card>
                    <slot name="actions" v-bind:item="item"></slot>
                  </v-card>
                </v-menu> </template></v-data-table></v-col
        ></v-row>
        <slot name="below"></slot>
      </v-container>
      <v-row v-if="isPaginationVisible"
        ><v-col
          ><div class="text-center">
            <v-pagination
              v-model="pageNumber"
              :length="length"
              total-visible="5"
            ></v-pagination></div></v-col
      ></v-row>
    </v-container>
  </div>
</template>
<script>
import { round } from 'lodash';
import { formatDate } from '../../mixins/formatDate';
export default {
  name: 'RegistrationForms',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formatDate],
  data() {
    return {
      itemsPerPage: 6,
      pageNumber: 1,
    };
  },
  watch: {
    pageNumber: function (newVal) {
      this.$emit('update:page', newVal);
    },
  },
  computed: {
    isPaginationVisible() {
      return this.$props.length > 1;
    },
  },
  methods: {
    roundedProfit(profit) {
      return round(profit, 2);
    },
  },
  created() {
    this.pageNumber = this.page;
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
