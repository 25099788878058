<template lang="">
  <v-row class="align-center" v-if="true">
    <v-col cols="12" sm="2">
      <v-text-field v-model="filters.search" hide-details placeholder="Szukaj" solo></v-text-field
    ></v-col>
    <v-col cols="12" sm="3">
      <status-filter v-bind:status.sync="filters.status"></status-filter
    ></v-col>

    <v-col cols="12" sm="3">
      <v-select
        v-model="filters.isInstallationPlanned"
        :items="isInstallationPlannedOptions"
        label="Montowanie"
        solo
        hide-details
      ></v-select
    ></v-col>

    <v-col cols="12" sm="2">
      <complete-filter v-bind:isCompleted.sync="filters.isCompleted"></complete-filter
    ></v-col>

    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark b lock @click="onSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn></v-col
    >
    <v-col cols="6" sm="1">
      <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
        <v-icon>mdi-cancel</v-icon>
      </v-btn></v-col
    >
  </v-row>
</template>

<script>
import StatusFilter from './filters/StatusFilter.vue';
import CompleteFilter from './filters/CompleteFilter.vue';

export default {
  name: 'FitterFilters',
  components: {
    CompleteFilter,
    StatusFilter,
  },
  data() {
    return {
      fitters: [],
      traders: [],
      statuses: [],
      isInstallationPlannedOptions: [
        {
          text: 'Montowanie zaplanowane',
          value: true,
        },
        {
          text: 'Montowanie niezaplanowane',
          value: false,
        },
      ],
      fitter: '',
      trader: '',
      filters: {
        search: '',
        status: '',
        isCompleted: '',
        isInstallationPlanned: '',
      },
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.filters);
    },
    clearParams() {
      this.filters = {
        search: '',
        trader: '',
        fitter: '',
        status: '',
        isCompleted: '',
      };
      this.trader = '';
      this.fitter = '';

      this.$emit('clearParams');
    },
  },
};
</script>
<style lang=""></style>
